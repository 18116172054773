<template>
  <b-modal v-model="isModalOpen" :can-cancel="false" :width="700">
    <BasketContent :purchase="purchase" v-if="loaded">
      <div class="close-btn cursor-pointer" @click="cancelModal">
        <b-icon icon-pack="fas" icon="times" type="is-grey-icon" />
      </div>
    </BasketContent>

    <div class="af-modal-card" v-else>
      <div class="modal-card__content">
        <p class="has-text-weight-bold is-size-4 mb-4">
          {{ $t("af:merchandise.basket.title") }}
        </p>

        <LoadingSkeleton />
      </div>
    </div>
  </b-modal>
</template>

<script>
import eventBus, { EVENTS } from "@/utils/eventBus";
import LoadingSkeleton from "../LoadingSkeleton";

export default {
  name: "BasketModal",
  components: {
    LoadingSkeleton,
    BasketContent: () => import("@/components/basket/BasketContent.vue"),
  },
  props: {},
  data() {
    return {
      isModalOpen: false,
      loaded: false,
      purchase: {},
    };
  },
  async mounted() {
    eventBus.$on(EVENTS.OPEN_BASKET_MODAL, this.openModal);
  },
  destroyed() {
    eventBus.$off(EVENTS.OPEN_BASKET_MODAL, this.openModal);
  },
  methods: {
    async openModal() {
      this.loaded = false;
      this.isModalOpen = true;

      await this.$store.dispatch("purchase/fetchActivePurchase");
      this.purchase = this.$store.getters["purchase/getActivePurchase"];
      this.loaded = true;
    },
    cancelModal() {
      this.isModalOpen = false;
    },
  },
};
</script>

<style scoped lang="scss"></style>
